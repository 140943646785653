<template>
  <v-card :height="'100%'" width="100%" style="border-left: 3px solid #5bb55f">
    <v-toolbar elevation="0" class="pa-0">
      <v-toolbar-title class="toolbar-work-place-title">
        Стан розрахунків
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <MainModal
          :main="{ component: 'FlatServiceLegacy', title: 'Послуга' }"
          :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"
          :item="{ person_hash: flat.person_hash, flat_name: flat.address }"
          v-if="!readOnly"
          :nonClickable="nonClickable"
          @updateItemModal="flatServiceUpdateItemModal"
      />
    </v-toolbar>
    <div class="form-table-custom mt-0" style="width: 100%">
      <table style="width: 100%">
        <thead>
        <tr>
          <th style="width: 70px"></th>
          <th rowspan="1" style="width: 240px; text-align: left; padding-left: 10px;">
            Послуга
          </th>
          <th rowspan="1" style="width: 160px; text-align: left; padding-left: 10px;">
            Договір
          </th>
          <th rowspan="1" style="width: 80px;">
            Об’єм
          </th>
          <th rowspan="1" style="width: 80px;">
            Тариф
          </th>
          <th rowspan="1">
            Сальдо поч.
          </th>
          <th rowspan="1">
            Нараховано
          </th>
          <th rowspan="1">
            Перерахунок
          </th>
          <th rowspan="1">
            ПДВ
          </th>
          <th rowspan="1">
            Всього
          </th>
          <th rowspan="1">
            Оплачено
          </th>
          <th rowspan="1">
            Сальдо кін.
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, idx) in items" :key="idx" @click="openFlatServiceDialog(item)"
            :class="item.is_total ? 'row-is-total' : ''"
        >
          <td style="width: 70px; text-align: left; padding-left: 10px !important;" v-if="!item.is_total">
            <template v-if="readOnly">
              <v-icon :color="getIcon(item.icon, 'color')" size="26" class="mr-2">
                {{ getIcon(item.icon, 'icon') }}
              </v-icon>
            </template>
            <template v-else>
              <MainModal
                  :main="{ component: 'FlatServiceLegacy', title: 'Послуга' }"
                  :button="{ iconActivator: true, class: 'mr-2', color: getIcon(item.icon, 'color'), buttonIcon: getIcon(item.icon, 'icon'), iconSize: 26 }"
                  :item="{
                      person_hash: flat.person_hash,
                      id: item.id,
                      contract_id: item.contract_id,
                      service_id: item.service_id,
                      date_start: item.date_start,
                      date_end: item.date_end,
                      update: true,
                      flat_name: flat.address
                    }"
                  :nonClickable="true"
                  :modal="nonClickable ? false : flat_service_modal_id === item.id"
                  @updateItemModal="flatServiceUpdateItemModal"
              />
            </template>
          </td>
          <td style="width: 240px; text-align: left; padding-left: 10px !important;" v-if="!item.is_total">
            {{ item.service_name }}
          </td>
          <td style="width: 160px; text-align: left; padding-left: 10px !important;" v-if="!item.is_total">{{ item.contract_name }}</td>
          <td colspan="5" v-if="item.is_total"
              style="text-align: right; font-weight: 500; padding-right: 6px !important;
              background-color: #f7f7f7; border-right: 1px solid #ececec"
          >
            {{ `Підсумок по дог.: ${item.contract_name}` }}
          </td>
          <td style="width: 80px;" v-if="!item.is_total">
            <span v-if="(item.volume - item.volume_normative) === item.volume">
              {{ item.volume }}
            </span>
            <span v-else-if="(item.volume - item.volume_normative) === 0">
                {{ item.volume }}
              </span>
            <template v-else>
              {{ (item.volume - item.volume_normative) }}
              <span>
                {{ item.volume }}
              </span>
              <span class="error--text">
                {{ ` (${(item.volume - item.volume_normative).toFixed(0)})` }}
              </span>
            </template>
          </td>
          <td style="width: 80px; font-weight: 500;" v-if="!item.is_total">
            {{ item.tariff | formatNumber('0.0000') }}
          </td>
          <td>
            <span
                class="font-weight-medium"
                :class="[
                {'error--text': item.start_balance > 0},
                {'success--text': item.start_balance < 0}
            ]"
            >{{ item.start_balance | formatToFixed(2) }}</span>
          </td>
          <td>{{ item.charge | formatToFixed(2) | formatNumber  }}</td>
          <td>{{ item.recalculation | formatToFixed(2) | formatNumber  }}</td>
          <td>{{ item.tax | formatToFixed(2) | formatNumber  }}</td>
          <td>{{ item.to_pay | formatToFixed | formatNumber  }}</td>
          <td>{{ item.pay | formatToFixed(2) | formatNumber  }}</td>
          <td>{{ item.end_balance | formatToFixed(2) | formatNumber }}</td>
        </tr>
        <tr style="background-color: #f5f5f5">
          <td colspan="5" style="text-align: right; padding-right: 4px !important; font-weight: 500;">
            Всього:
          </td>
          <td>
            {{ getTotal('start_balance') | formatNumber  }}
          </td>
          <td>
            {{ getTotal('charge') | formatNumber  }}
          </td>
          <td>
            {{ getTotal('recalculation') | formatNumber  }}
          </td>
          <td>
            {{ getTotal('tax') | formatNumber  }}
          </td>
          <td>
            {{ getTotal('to_pay') | formatNumber  }}
          </td>
          <td>
            {{ getTotal('pay') | formatNumber  }}
          </td>
          <td>
            {{ getTotal('end_balance')  | formatNumber  }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_WITH_BALANCE_LEGACY} from "@/store/actions/flat_service";
import {accountIcons} from "@/utils/icons";

export default {
  name: "HWL_Accounts",
  props: {
    nonClickable: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textCard: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String,
      default: 'Поточний стан розрахунку по абоненту'
    },
    person_hash: {
      type: String,
      default: ''
    },
    bigger: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getFlatServiceWithBalanceLegacy',
      flat: 'getLegacyFlat'
    }),
  },
  data() {
    return {
      flat_service_modal_id: 0,
    }
  },
  methods: {
    ...mapActions({
      fetchFlatServices: GET_ALL_WITH_BALANCE_LEGACY
    }),
    getIcon(name, type) {
      const defaultIcon = {icon: 'mdi-text-box-check-outline', color: 'success'}
      const value = accountIcons[name] || defaultIcon
      return value[type]
    },
    getTotal(column) {
      return this.items.filter(item => !item.is_total).reduce((total, current) => {
        return total += current[column]
      }, 0).toFixed(2)
    },
    openFlatServiceDialog(row) {
      if (row.id === null) {
        this.flat_service_modal_id = 0
        return
      }
      this.flat_service_modal_id = row.id
    },
    flatServiceUpdateItemModal(payload) {
      this.flat_service_modal_id = 0
      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },

  },
  mounted() {
    if (this.$route.params.person_hash) {
      this.fetchFlatServices(this.$route.params.person_hash)
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetchFlatServices(payload)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  tr > td {
    cursor: pointer;
  }
  .row-is-total > td {
    background-color: #f7f7f7;
    font-weight: 500;
  }
</style>